@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap);
html {
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif;
  color: #555;
  font-size: 16px;
}
@media (min-width: 6.25em) {
  html {
    font-size: 17px;
  }
}
@media (min-width: 12.5em) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 18.75em) {
  html {
    font-size: 19px;
  }
}
@media (min-width: 25em) {
  html {
    font-size: 20px;
  }
}
@media (min-width: 31.25em) {
  html {
    font-size: 21px;
  }
}
@media (min-width: 37.5em) {
  html {
    font-size: 22px;
  }
}
@media (min-width: 43.75em) {
  html {
    font-size: 23px;
  }
}
@media (min-width: 50em) {
  html {
    font-size: 24px;
  }
}
@media (min-width: 56.25em) {
  html {
    font-size: 25px;
  }
}
@media (min-width: 62.5em) {
  html {
    font-size: 26px;
  }
}
@media (min-width: 68.75em) {
  html {
    font-size: 27px;
  }
}
@media (min-width: 75em) {
  html {
    font-size: 28px;
  }
}
@media (min-width: 81.25em) {
  html {
    font-size: 29px;
  }
}
@media (min-width: 87.5em) {
  html {
    font-size: 30px;
  }
}
@media (min-width: 93.75em) {
  html {
    font-size: 31px;
  }
}
@media (min-width: 100em) {
  html {
    font-size: 32px;
  }
}
@media (min-width: 106.25em) {
  html {
    font-size: 33px;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 34px;
  }
}
@media (min-width: 118.75em) {
  html {
    font-size: 35px;
  }
}
@media (min-width: 125em) {
  html {
    font-size: 36px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/**
 * The fixed Side 
 */
.side {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 50px;
  border-right: 1px dashed rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.5);
  font-family: "nimbus-sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0875rem;
  font-size: 10px;
  transition: all 0.3s cubic-bezier(0.05, 0.69, 0.14, 1);
}
.side:hover {
  color: #12f0f0;
}
.side .side__inner {
  position: relative;
  height: 100%;
  white-space: nowrap;
}
.side a {
  color: inherit;
  word-spacing: 0;
  transition: all 0.3s cubic-bezier(0.05, 0.69, 0.14, 1);
}
.side .top,
.side .bottom {
  position: absolute;
  left: 0;
  transform: rotate(-90deg) perspective(1px);
  transform-origin: 50px 50px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  word-spacing: 0.5rem;
}
.side .bottom i {
  font-size: 15px;
  padding: 1px;
  transform: rotate(90deg) perspective(1px);
}
.side .top {
  top: 55px;
  text-align: right;
  color: #1891e2;
}
.side .top:hover a:hover {
  color: #12f0f0;
}
.side .bottom {
  color: #1891e2;
  top: auto;
  bottom: 75px;
}
.side .bottom:hover a {
  color: #037adb;
}
.side .bottom:hover a:hover {
  color: #12f0f0;
}

/**
 * The first navigation
 */
.anchornav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.anchornav li {
  display: block;
}
.anchornav li::after {
  content: "";
  width: 1.3rem;
  display: block;
  height: 2px;
  background: black;
}
.anchornav li:last-child::after {
  display: none;
}
.anchornav a {
  display: block;
  padding: 1em 0;
  font-family: "nimbus-sans", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.4px;
  color: inherit;
  transition: all 0.3s cubic-bezier(0.05, 0.69, 0.14, 1);
}
.anchornav a span {
  display: inline;
  padding: 0.5em 0 0;
  opacity: 0.3;
  letter-spacing: normal;
  font-family: "Red Hat Display", sans-serif;
  text-transform: none;
}
.anchornav a:hover {
  color: #478c71;
}
.anchornav a:hover span {
  opacity: 1;
  color: #181818;
}

/**
 * The different sections
 */
.section {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-height: 60vh;
  width: 100%;
  align-content: center;
  padding: 20vh 50px 20vh 75px;
}
.section svg {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
}
.section svg.bottom {
  bottom: 0;
  top: auto;
  fill: grey;
}
.section .section__inner {
  height: 100%;
  max-width: 800px;
  margin: auto auto auto 0;
}
.section.section--hello {
  background-color: #0c0c0c;
  color: #ffffff;
}
.section.section--about {
  background-color: white;
  color: rgb(48, 46, 46);
}
.section.section--background {
  background-color: #0c0c0c;
  color: #ffffff;
}
.section.section--background svg {
  fill: #0c0c0c;
}
.section.section--projects {
  background-color: #ffffff;
  color: rgb(48, 46, 46);
}
.section.section--projects svg {
  fill: #ffffff;
}
.section.section--experience {
  background-color: #ffffff;
  color: rgb(48, 46, 46);
}
.section.section--experience svg {
  top: -20px;
  fill: #ffffff;
}
.section.section--experience svg.bottom {
  top: auto;
  bottom: -50px;
}
.section.section--experience1 {
  background-color: #0c0c0c;
  color: white;
}
.section.section--experience1 svg {
  top: -50px;
  fill: #0c0c0c;
}
.section.section--pepperhill svg {
  top: -50px;
  fill: #3a99d8;
}
.section.section--coffewithme {
  background-color: #0c0c0c;
  color: white;
}
.section.section--coffewithme svg {
  fill: #0c0c0c;
}

/**
 * Typo
 */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h5 {
  font-family: "nimbus-sans", sans-serif;
  font-weight: bold;
  margin: 0 0 1em;
  line-height: 1;
  letter-spacing: -1px;
}
@media (min-width: 30rem) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h5 {
    letter-spacing: -2px;
  }
}
h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h5 a {
  color: inherit;
}
h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h5 a:hover {
  border-bottom: 0.1875rem solid;
}
h1 span,
.h1 span,
h2 span,
.h2 span,
h3 span,
.h3 span,
h4 span,
.h5 span {
  color: #50c974;
}

h1,
.h1 {
  font-size: 1.2rem;
}
@media (min-width: 30rem) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 1.2rem;
}
@media (min-width: 30rem) {
  h2,
  .h2 {
    font-size: 2.5rem;
  }
}

.pre {
  display: inline-block;
  font-size: 12px;
  margin: 0;
  opacity: 0.8;
  letter-spacing: 1.4px;
  word-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 1em;
  color: inherit;
}
.pre span {
  font-size: 0.9em;
  margin-right: 0.4em;
  opacity: 0.5;
}
.pre:hover {
  opacity: 1;
}

p {
  font-size: 0.75rem;
  line-height: 1.4;
}
@media (min-width: 30rem) {
  p {
    font-size: 0.875rem;
  }
}
p a {
  font-weight: bold;
  color: inherit;
}
p a:hover {
  /* border-bottom: 0.1875rem solid; */
  color: #39a77c;
}

.lead {
  display: block;
  font-size: 0.875rem;
  margin: 0;
}
@media (min-width: 30rem) {
  .lead {
    font-size: 1rem;
  }
}

.experience {
  font-size: 0.75rem;
}
.experience.block {
  display: inline-block;
  width: 100%;
}
@media (min-width: 30rem) {
  .experience.block {
    width: 30%;
  }
}
.experience .link {
  display: block;
  margin: 2em 0 0 0;
  color: #555;
  letter-spacing: 2px;
  font-size: 12px;
}
.experience p {
  font-size: inherit;
  color: #343436;
  opacity: 0.7;
}

/*
animations
*/

